<template>
  <div class="vue-template">
    <div class="App">
      <div class="vertical-center">
        <nav class="navbar-light">
          <nav-bar></nav-bar>
        </nav>
        <div class="inner-block">
          <div class='form'>
            <dropDown></dropDown>
            <router-view />
          </div>
        </div>
        <div class="company">
          <div>   
            <span><strong><a href="https://www.plettware.com" target="_blank">Plettware LLC</a>&nbsp;&nbsp;<span class="material-icons-sharp phone">phone_iphone</span>&nbsp;&nbsp;(918)&nbsp;766-4775</strong></span>
          </div>   
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import navBar from '@/components/NavBar';
  import dropDown from '@/components/DropdownContent.vue'
    export default  {
      name: 'home',
      components: {
          navBar: navBar,
          dropDown: dropDown
      },
      data() {
        return {
          images: [],
          currentNumber: 0,
          timer: null
        }
      },
      mounted() {
        this.importAll(require.context('./assets', true, /.\.jpg$/));
        this.startRotation();
      },
      methods: {
        importAll(r) {
          r.keys().forEach(key => (this.images.push({ pathLong: r(key), pathShort: key })));
        },
        startRotation() {
          this.timer = setInterval(this.next, 3000);
        },
        stopRotation() {
          clearTimeout(this.timer);
          this.timer = null;
        },
        next() {
          this.currentNumber += 1
        },
        prev() {
          this.currentNumber -= 1
        }
      },
      computed: {
        currentImage() {
          return this.images[Math.abs(this.currentNumber) % this.images.length].pathLong;
        }
      }
  };
</script>