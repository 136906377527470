import firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCsbTIMHikNy12iSWS-O9e-nvOoeYeNOks",
    authDomain: "claremore2021-48511.firebaseapp.com",
    projectId: "claremore2021-48511",
    storageBucket: "claremore2021-48511.appspot.com",
    messagingSenderId: "386797166256",
    appId: "1:386797166256:web:7542cb58e0945b1eaa2575",
    measurementId: "G-8ZVG8MT8Y3"
};
  
  firebase.initializeApp(firebaseConfig);
  
  let db = firebase.firestore();
  let auth = firebase.auth();
  
  export { db, auth };
