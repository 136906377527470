<template>
    <transition name="dropdown-content">
      <div v-if="active" class="dropDown">
        <router-link class="dropDownContent" :to="{name: 'profile', params: { id: user.uid}}" title="Update User Profile">Profile</router-link>
        <router-link class="dropDownContent" :to="{name: 'import', params: { id: user.uid}}" title="Import Contestants">Import</router-link>
      </div>
    </transition>
  </template>
  
  <script>
  import { auth } from '@/services/firebase/index.js';

  export default {
    name: "AppDropdownContent",
    data: function data() {
      return {
        user: '',
      };
    },
    computed: {
      active() {
        return this.$store.getters.getDropDownActive;
      }
    },
    created() {
        auth.onAuthStateChanged((user) => {
            this.user = user;
        });
    }
  };
  </script>
  
  <style>
    .dropdown-content-enter-active,
    .dropdown-content-leave-active {
      transition: all 0.2s;
    }
    .dropdown-content-enter,
    .dropdown-content-leave-to {
      opacity: 0;
      transform: translateY(-5px);
    }

    .dropDown {
      position: absolute;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      background-color: #f9f9f9;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      padding: 2px 6px;
      z-index: 999;
      right: 0;
      top: 65px
    }

    .dropDownContent {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
  
    .dropDownContent:hover {
      background-color: #f1f1f1;
    }
  </style>
  