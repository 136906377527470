import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueLodash from 'vue-lodash';
import veeValidate from 'vee-validate';
import '@mdi/font/css/materialdesignicons.css'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import lodash from 'lodash'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-icons/iconfont/material-icons.css';
import '@/assets/css/main.css'

Vue.use(lodash);
Vue.use(VueLodash);
Vue.use(Buefy);

Vue.use(veeValidate, {
  inject: true,
  fieldsBagName: 'veeFields'
});

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
